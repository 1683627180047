export const TRANSLATIONS_ES = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Error de servidor interno',
    UNAUTHORIZED: 'No autorizado',
    TOKEN_EXPIRED: 'Token caducado',
    NOT_FOUND: 'Servicio no encontrado',
    USER_DUPLICATED: 'Usuario duplicado',
    BAD_REQUEST: 'Solicitud incorrecta',
    USER_NOT_FOUND: 'Usuario no encontrado',
    PHONE_REQUIRED: 'Requiere telefono',
    PHONE_DUPLICATED: 'Número de teléfono duplicado',
    EMAIL_REQUIRED: 'Correo electronico requerido',
    EMAIL_DUPLICATED: 'Correo electrónico duplicado',
    PASSWORD_REQUIRED: 'Se requiere contraseña',
    WRONG_PASSWORD: 'Contraseña incorrecta',
    INVALID_USER: 'Usuario invalido',
    NOT_EMAIL_OWNER: 'Tu no eres el propietario de este correo electrónico',
    NOT_PHONE_OWNER: 'Tu no eres el propietario de este teléfono',
    EMAIL_NOT_FOUND: 'Correo electrónico no encontrado',
    PHONE_NOT_FOUND: 'Teléfono no encontrado',
    SENDING_EMAIL_ERROR: 'Envío fallido de correo electrónico',
    SENDING_MESSAGE_ERROR: 'Envío fallido de mensaje',
    NO_PERMISSION: 'No tienes permiso para hacer esta acción',
    INSTITUTION_NOT_FOUND: 'Institución no encontrada',
    ACCOUNT_NOT_VERIFIED: 'Cuenta no verificada',
    INVALID_TOKEN: 'Invalid token',
    SOMETHING_WRONG: 'Algo salió mal, por favor, ponte en contacto con soporte técnico',
    SOCIAL_ACCOUNT_NOT_FOUND: 'La cuenta social no existe o usted no es dueño de esa cuenta',

    ERROR_AVATAR_FILE_SIZE: 'Tamaño del archivo muy grande',

    ACCOUNT_UPDATED_SUCCESS: 'Cuenta actualizada exitosamente',
    CONTACT_UPDATED_SUCCESS: 'Contacto actualizado exitosamente',
    UPDATE_PASSWORD_SUCCESS: 'Contraseña actualizada exitosamente',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verificación exitosa del correo electrónico',
    SEND_VERIFY_CODE_SUCCESS: 'Verificación exitosa del código',
    CREATE_OWNER_SUCCESS: 'Has agregado un nuevo propietario. El nuevo propietario tiene que verificar su cuenta',
    CREATE_ADMIN_SUCCESS: 'Has agregado un/a nuevo/a administrador/a',
    DELETE_ADMIN_SUCCESS: 'Has eliminado un/a administrador/a',

    // FORM VALIDATION
    fieldRequired: 'Este campo es obligatorio',
    fieldInvalid: 'Valor no válido',
    weakPassword: 'Usa 8 o más caracteres con una combinación de letras, números y símbolos',
    fieldUnconfirmed: 'Por favor confirme',

    // FORM PLACEHOLDER
    user: 'Usuario',
    password: 'Contraseña',
    emailOrPhone: 'Dirección de correo electrónico o número de teléfono celular',
    enterCode: 'Ingrese su código',
    selectArea: 'Seleccione las areas',
    selectNativeLanguage: 'Seleccione idioma nativo',
    selectOtherLanguage: 'Seleccione otros idiomas',
    select: 'Seleccionar',

    // FORM LABEL
    name: 'Primer Nombre',
    lastname: "Primer Apellido",
    secondName: "Segundo Nombre",
    secondLastname: "Segundo Apellido",
    birthday: "Fecha de Nacimiento",
    nationality: 'Nacionalidad',
    idNumber: 'Número de Identificación',
    email: 'Correo Electrónico',
    phone: 'Teléfono Celular',
    repeatPassword: 'Repita su contraseña',
    enterEmailPhone: 'Ingrese su correo electrónico o teléfono celular',
    verificationCodeToEmail: 'Un código de verificación para restaurar su contraseña fue enviado a su correo electrónico',
    enterNewPassword: 'Ingrese su nueva contraseña',
    repeatNewPassword: 'Repita su nueva contraseña',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado/Provincia',
    secondNationality: 'Segunda nacionalidad',
    secondIdentification: 'Segunda identificación',
    secondPassport: 'Segundo pasaporte',
    avatarOption1: "Seleccione una imagen de su computadora (máx. 1MB)",
    avatarOption2: "Cargue imagen desde link",
    avatarOption3: "Usar imagen por default",
    actual: "Actual",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirme nueva contraseña",
    interestArea: "Áreas de interés",
    nativeLanguage: "Idioma nativo",
    otherLanguage: "Otros Idiomas",
    surname: "Apellido",
    secondSurname: "Segundo Apellido",
    address: 'Dirección',
    editImage: "Editar imagen",
    ok: "OK",
    sector: "Sector",
    subSector: "Subsector",
    numberOfStudent: "Número de estudiantes",
    noInfo: "No info",
    numberOfCourse: "Número de cursos",
    since: "Desde",
    institutionName: 'Nombre de la institución',
    institutionEmail: 'Correo electrónico de la institución',
    institutionPhone: 'Número telefónico de contacto',
    dateOfOrigin: 'Fecha de creación',
    description: 'Descripción',
    googleMapLink: 'Link a Google maps',
    website: 'Sitio web',

    // LOGIN PAGE
    savePassword: 'Guarde la contraseña y el usuario para un inicio de sesión futuro',
    login: 'Iniciar sesión',
    loginWithGoogle: 'Iniciar sesión con Google',
    loginWithFacebook: 'Iniciar sesión con Facebook',
    loginWithLinkedIn: 'Iniciar sesión con LinkedIn',
    loginWithMicrosoft: 'Iniciar sesión con Microsoft',
    forgotPassword: 'Se te olvidó tu contraseña',
    newToUbot: 'Nuevo en Ubot',
    signup: 'Registrarse',
    welcomeToUbot: 'Bienvenido a Ubot',
    selectLanguage: 'Seleccione el idioma',

    // HOME
    bannerDescription: "Somos el nuevo sistema de acompañamiento y seguimiento educativo de aulas gestionadas por WhatsApp",
    register: "Registrarse",
    homeCharacterTitle1: "Seguimiento de evaluaciones",
    homeCharacterDescription1: "U·Bot le brinda a los docentes herramientas de seguimiento esenciales para mejorar el proceso de aprendizaje de sus estudiantes.",
    homeCharacterTitle2: "Mensajería en tiempo real",
    homeCharacterDescription2: "Mensajería de WhatsApp Integrada para que no te pierdas ningún avance y estés en contacto permanente con tus docentes, tutores, asesores y compañeros.",
    homeCharacterTitle3: "Ayuda disponible las 24hs",
    homeCharacterDescription3: "Te presentamos a Smarty, un robot con inteligencia artificial que ayudará al profesor y al alumno las 24 horas durante 365 días del año.",
    homeCharacterDescription3Important: "Smarty será cada vez más inteligente.",
    institutionSectionTitle: "Instituciones que nos acompañan",
    characterItemSectionTitle: "Características principales",
    characterItemDescription1: "Chatbot especializado en educación",
    characterItemDescription2: "Seguimiento de alumno mediante hitos de aprendizaje",
    characterItemDescription3: "Administración y registro procesos académicos",
    characterItemDescription4: "Métricas y datos para organización de grupos de alumnos",
    characterItemDescription5: "Recordatorios, videos, infografías, consultas a través de WhatsApp",
    characterItemDescription6: "Herramientas para que las instituciones puedan tomar decisiones estratégicas",
    characterItemDescription7: "Sistema de motivación y refuerzos positivos en base a logros y avances",
    characterItemDescription8: "Capacitando a docentes del futuro",
    demo: "Demo",
    contact: "Contacto",
    copyRight: '© Todos los derechos reservados',

    // SIGNUP PAGE
    signupWithEmailPassword: 'Registrarse con correo electrónico y contraseña',
    signupWithGoogle: 'Registrarse con Google',
    signupWithFacebook: 'Registrarse con Facebook',
    signupWithLinkedIn: 'Registrarse con LinkedIn',
    signupWithMicrosoft: 'Registrarse con Microsoft',
    backToCreateAccount: 'Volver para crear una cuenta',
    contactInfo: 'Datos de contacto',
    signupAgreement1: 'Al crear una cuenta, declaro que he leído y acepto Ubot',
    termOfUse: 'Condiciones de uso',
    and: 'y',
    privacyPolicy: 'Políticas de Privacidad',
    haveAnAccount: 'Tiene una cuenta',
    confirmYourEmail: 'Confirmar cuenta de correo electrónico',
    sendVerificationEmail: 'Te enviaremos un correo de verificación ',
    notReceivedVerificationEmail: 'Si no has recibido el correo de confirmación, chequea spam o ',
    forwardEmail: 'reenvía el correo desde aquí',

    // FORGOT PASSWORD PAGE
    findYourAccount: "Encuentra tu cuenta",
    search: "Buscar",
    checkYourEmail: 'Consultar su correo electrónico',
    verify: 'Verificar',
    resetYourPassword: 'Restablecer su contraseña',
    resetPassword: 'Restablecer la contraseña',
    rememberPassword: 'Recuérdame',

    // VERIFY ACCOUNT PAGE
    accountAlreadyVerifiedDescription: "Tu cuenta ha sido verificada",
    emailAlreadyVerifiedDescription: "Tu correo electrónico ha sido verificado",
    accountVerifiedSuccessDescription: "¡Felicitaciones, tu cuenta ha sido confirmada!",
    emailVerifiedSuccessDescription: "¡Felicitaciones, tu correo electrónico ha sido confirmado!",
    accountVerifiedFailedDescription: "Ups, la verificación de tu cuenta ha fallado",
    emailVerifiedFailedDescription: "Ups, la verificación de tu correo electrónico ha fallado",
    goBackToUbot: "Volver a Ubot",

    // ACCEPT INVITATION PAGE
    acceptMessage: 'Felicitaciones, has sido registrado como {{ role }} en {{ institutionName }}',
    roleName1: 'Propietario',
    roleName2: 'Administrador',
    roleName3: 'Profesor',
    roleName4: 'Estudiante',

    // LMS Layout

    // Welcome PopUp
    emailAddressConfirmed: 'Tu correo electrónico ha sido confirmado',
    loremIpsum: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
    youCanStudyAnything: 'Tu puedes estudiar lo que desees',
    searchCourse: 'Buscar curso',
    wantBeTeacher: 'Quiero ser un docente',
    createYourCourse: 'Crear tus propios cursos',
    wantCreateInstitution: 'Quieres crear tu institución',
    createInstitution: 'Crear institución',
    welcomePopUpDescription1: 'Soy Smarty, tu compañero de aprendizaje! envíame un Hola! para quedar conectados',
    welcomePopUpDescription2: 'Busca formarte en cursos de tu interés',
    welcomePopUpDescription3: 'Puedes crear y compartir tus cursos en este espacio',
    welcomePopUpDescription4: 'En este espacio de gestión de aprendizaje las instituciones toman su protagonismo. Puedes crear instituciones educativas, gubernamentales, independientes, empresas, etc.',
    next: 'Próximo',
    back: 'Atrás',
    gotIt: 'Lo tengo',

    // HEADER
    courses: 'Cursos',
    tutorials: 'Tutoriales',
    createYourSite: 'Crea tu propio espacio',
    createYourInstitution: 'Crea tu institución',
    profile: 'Perfil',
    configuration: 'Configuración',
    messageAndNotification: 'Mensajes y notificaciones',
    darkMode: 'Modo oscuro',
    signOut: 'Cerrar Sesión',
    allRightReserved: '© Todos los derechos reservados',

    // ACCOUNT PAGE
    generalInformation: 'Información general',
    contactInformation: 'Información de contacto',
    summery: 'Biografía',
    security: 'Seguridad',

    profilePhoto: 'Imagen de perfil',
    change: 'Cambiar',
    selectFile: 'Seleccionar archivo',
    personalInformation: 'Información personal',
    edit: 'Editar',
    allName: 'Nombre/s y Apellido/s',
    gender: 'Género',
    residenceCity: 'Ciudad de residencia',
    residenceState: 'Estado/Provincia de residencia',
    residenceCountry: 'País de residencia',
    timezone: 'Zona horaria',
    identification: 'Identificación',
    passport: 'Pasaporte',
    genderundefined: 'Desconocido',
    gender0: 'Otro',
    gender1: 'Masculino',
    gender2: 'Femenino',
    addNationality: 'Agregar nacionalidad',
    cancel: 'Cancelar',
    save: 'Guardar',
    accounts: 'Cuentas',
    noLinkedAccount: 'No tienes cuenta vinculada',
    registeredWith_google: 'Registrado con Google',
    registeredWith_facebook: 'Registrado con Facebook',
    registeredWith_linkedin: 'Registrado con LinkedIn',
    registeredWith_microsoft: 'Registrado con Microsoft',
    confirmDeleteSocial: '<span style="font-weight: normal;">¿Desea eliminar <b>{{account}}</b>?</span>',
    socialAccount_google: 'Cuenta Google',
    socialAccount_facebook: 'Cuenta de Facebook',
    socialAccount_linkedin: 'Cuenta de LinkedIn',
    socialAccount_microsoft: 'Cuenta de Microsoft',
    confirmDeleteSocialDescription: 'Esta acción es definitiva, si elimina esta cuenta, se desvinculará.',
    mainEmail: 'Correo electrónico principal',
    otherEmail: 'Otro email',
    mainPhone: 'Celular principal',
    otherPhone: 'Otro/s celular/es',
    whatsapp: 'WhatsApp',
    telegram: 'Telegram',
    telegramAccount: 'Cuenta de Telegram',
    verifyAccount: 'Verificar cuenta',
    verifiedEmail: 'Todo es correcto, este correo electrónico está verificado',
    unVerifiedEmail: 'Este correo electrónico no ha sido verificado',
    unVerifiedAccount: 'Cuenta no verificada',
    verifiedAccount: 'Cuenta verificada',
    verifiedPhone: 'Todo es correcto, este número está verificado',
    unVerifiedPhone: 'Este número no ha sido verificado',
    resendVerificationEmail: 'Reenviar correo de verificación',
    sendVerificationEmailButton: 'Enviar correo de verificación',
    sendVerificationCodeButton: 'Enviar código de verificación',
    resendVerificationMessage: 'Reenviar mensaje de verificación',
    addEmail: 'Agregar correo electrónico',
    addPhone: 'Agregar celular',
    verifyTelegramAccount: 'Verificar cuenta de Telegram',
    stepNumber: 'Paso {{step}}',
    telegramVerificationStep1: 'Ingresar a Telegram',
    telegramVerificationStep2_1: 'Dirigirse al siguiente link',
    telegramVerificationStep2_2: 'o buscar el usuario',
    telegramVerificationStep3: 'Enviar a través de este medio el correo electrónico con el cuál estás registrado en este sistema',
    enterVerificationCode: 'Ingresar código de verificación',
    makeSureDigitCode: 'Asegúrate de que reciviste el código de 6 dígitos',
    resendCode: 'Reenviar código',
    coverLetter: 'Biografía',
    tagTitle: 'Estoy interesado en',
    userLanguageTitle: 'Idiomas',
    mainUserLanguage: 'Idioma principal',
    otherUserLanguage: 'Otros idiomas',
    changePassword: 'Cambiar contraseña',

    // UBOT HOME
    ubotHome: 'Página de inicio de Ubot',
    noInstitution: 'Aún no tienes ninguna institución',
    noInstitutionConfiguration: 'Tienes instituciones para ser aprobadas',
    completeProfileMessage: 'Recuerda completar tu perfil ',
    completeProfileMessage1: 'aquí :)',
    homeCardTitle1: 'Quieres estudiar algo?',
    homeCardTitle2: 'Quieres enseñar?',
    homeCardTitle3: 'Crear una nueva institución',
    homeCardSubTitle1: 'Cursos abiertos',
    homeCardButton1: 'Buscar cursos',
    homeCardButton2: 'Crear un curso',
    homeCardButton3: 'Crear institución',
    homeCardDescription1: 'Busca formarte en cursos de tu interés',
    homeCardDescription2: 'Puedes crear y compartir tus cursos en este espacio',
    homeCardDescription3: 'En este espacio de gestión de aprendizaje las instituciones toman su protagonismo. Puedes crear instituciones educativas, gubernamentales, independientes, empresas, etc.',

    // UBOT INSTITUTION CREATE
    backToHome: 'Volver al inicio',
    choosePlanTitle: '¡Comencemos a crear la institución perfecta para ti!',
    choosePlanDescription: 'Por favor, selecciona algunas opciones a continuación para que Smarty pueda ayudarte a encontrar un plan ideal para tus necesidades.',
    searchMyPlan: 'Buscar mi plan',
    searchAgain: 'Buscar nuevamente',
    customizePlanTitle: 'Customizar mi plan',
    confirmPlanTitle: 'Confirmar plan',
    confirmPlanDescription: 'Déjanos tus datos de contacto, y a la brevedad una persona del área comercial se comunicará contigo',
    institutionInformation: 'Información de la institución',
    billingAddress: 'Dirección de facturación',
    confirmPlan: 'Confirmar plan',
    institutionSuccessEmailTitle: '¡Gracias por crear una institución con nosotros!',
    institutionSuccessEmailDescription: 'Recibirá un correo de verificación con los detalles de pago',
    perMonth: 'P / mes',
    requestDemo: 'Solicitar DEMO',
    freePlan: 'Plan gratuito',
    requestDemoDescription: 'Seleccione esta opción para crear una demostración específica para usted',
    customizePlan: 'Personalizar plan',
    customizePlanDescription: 'Seleccione esta opción para crear una demostración específica para usted',
    RECOMMENDED_PLAN: 'PLAN RECOMENDADO',
    DEMO_PLAN: 'PLAN DE DEMOSTRACIÓN',
    FREE_PLAN: 'PLAN GRATIS',
    CUSTOMIZE_PLAN: 'PERSONALIZAR PLAN',
    selectPlan: 'Seleccionar plan',
    editPlan: 'Editar plan',
    selectCharacterOfInstitution: 'Seleccione las características de su institución',
    contactUs: 'Contacta con nosotros',

    currency: 'Divisa',
    currency_usd: 'USD Dólar estadounidense',
    currency_clp: 'CLP Peso Chileno',
    currency_ars: 'ARS Peso Argentino',
    currency_brl: 'BRL Real brasileño',

    productDescription_institution_course_number: 'Cantidad máxima de {{number}} cursos',
    productDescription_institution_student_number: 'Máximo de {{number}} estudiantes',
    productDescription_use_telegram: 'Usar Telegram',
    productDescription_use_whatsapp: 'Usar WhatsApp',
    productDescription_course_student_number: 'Máximo de {{number}} estudiantes por curso',
    productDescription_storage: '{{number}} GB de datos guardados',
    productDescription_other_platform: 'Integración con otras plataformas',
    productDescription_access_analytics: 'Acceso a analíticas',
    productDescription_access_communication: 'Acceso a la comunicación',
    productDescription_survey_number: 'Máximo de {{number}} encuestas',
    productDescription_workspace_number: 'Máximo de {{number}} espacios de trabajo',
    productDescription_message_number: 'Máximo de {{number}} mensajes',

    sectorName_education: 'Educación',
    sectorName_business: 'Empresa / Negocio',
    sectorName_ngo: 'ONG / Fundación',
    sectorName_go: 'Institución gubernamental',
    sectorName_person: 'Profesor / profesor independiente',
    noSubSector: 'Sin subsector',

    subSectorName_public_university: 'Universidad pública',
    subSectorName_private_university: 'Universidad privada',
    subSectorName_public_tertiary_education: 'Educación terciaria pública',
    subSectorName_private_tertiary_education: 'Educación terciaria privada',
    subSectorName_public_college: 'Universidad pública / escuela secundaria',
    subSectorName_private_college: 'Universidad privada / escuela secundaria',
    subSectorName_institution: 'Instituto',

    subSectorName_farming: 'Agricultura; plantaciones, otros sectores rurales',
    subSectorName_feeding: 'Alimentación; bebidas tabaco',
    subSectorName_commerce: 'Comercio',
    subSectorName_construction: 'Construcción',
    subSectorName_education: 'Educación',
    subSectorName_transport_manufacture: 'Fabricación de material de transporte',
    subSectorName_public_function: 'Función pública',
    subSectorName_hotel_restoraunt: 'Hoteles, restaurantes, turismo',
    subSectorName_chemical_industry: 'Industrias quimicas',
    subSectorName_medical_electrical_engineering: 'Ingeniería mecánica y eléctrica',
    subSectorName_media_culture: 'Medios, cultura; gráficos',
    subSectorName_mining: 'Minería (carbón, otra minería)',
    subSectorName_oil_gas_production: 'Producción de petróleo y gas; refinación de petróleo',
    subSectorName_base_metal_production: 'Producción de metales base',
    subSectorName_postal_telecommunication_service: 'Servicios postales y de telecomunicaciones',
    subSectorName_health_service: 'Servicios de salud',
    subSectorName_financial_service: 'Servicios financieros; servicios profesionales',
    subSectorName_utilities: 'Servicios públicos (agua, gas, electricidad)',
    subSectorName_forestry: 'Silvicultura; madera, celulosa; papel',
    subSectorName_transportation: 'Transporte (incluida la aviación civil; ferrocarriles; transporte por carretera)',
    subSectorName_marine_transport: 'Transporte marítimo; puertos, pesca; transporte interno',

    // UBOT INSTITUTION DASHBOARD
    admin: 'Administrador',
    teacherTrainer: 'Facilitador / Docente',
    studentApprentice: 'Estudiante / Aprendíz',
    home: 'Inicio',
    viewInstitution: 'Ver institución',
    goToAcademics: 'Ir a Academic',
    statistics: 'Estadísticas',
    goToAnalytics: 'Ir a Analytics',
    messages: 'Mensajes',
    goToCommunication: 'Ir a Communication',
    activeCourse: 'Cursos activos',
    teachers: 'Profesores',
    activities: 'Actividades',
    activeTeacher: 'Docentes activos',
    activeStudent: 'Estudiantes activos',
    courseInProgress: 'Cursos en progreso',
    approved: 'Aprobados',
    disapproved: 'Desaprobados',
    courseEscape: 'Tasa de abandono',
    youHaveUncheckedMessage: 'Tu tienes {{ number }} mensajes sin leer',
    youHaveUnsentSurvey: 'Tu tienes {{ number }} encuestas sin enviar',
    youHaveNoCourse: 'No hay cursos disponibles',
    totalNumberOfCourse: 'Número total por cursos',
    totalNumberOfCourseDescription: 'Tienes {{number}} cursos en tu institución',
    totalNumberOfCourseDescriptionTeacher: 'Está inscrito en {{number}} cursos',
    courseInProgressDescription: 'Tienes {{number}} cursos activos',
    totalNumberOfTeacher: 'Número total de profesores',
    totalNumberOfTeacherDescription: '{{number}} profesores',
    totalNumberOfStudent: 'Número total de estudiantes',
    totalNumberOfStudentDescription: '{{number}} estudiantes',
    chats: 'Chats',
    chatsDescription: 'Tienes {{number}} mensajes sin leer',
    surveysInProcess: 'Encuestas en proceso',
    surveysInProcessDescription: 'Tienes {{number}} encuestas sin enviar',
    contacts: 'Contactos',
    contactsDescription: 'Tienes {{number}} contactos en tu agenda',
    statisticDescription: 'Aquí puedes ver las analíticas educativas de tu institución:',
    performance: 'Rendimiento',
    approvalRate: 'Tasa de aprobación',
    andMore: 'y más',
    numberOfActivityToQualify: 'Número de actividades para calificar',
    numberOfActivityToQualifyDescription: '{{number}} actividades para calificar',
    featuresForTeachers: 'Características de Ubot para profesores',
    goToTutorials: 'Ir a tutoriales',
    tutorialDescription: 'Aquí podrás:',
    tutorialDescription1: 'Sube y crea el contenido de tus cursos',
    tutorialDescription2: 'Calificar',
    tutorialDescription3: 'Chat',
    tutorialDescription4: 'Sigue el progreso de tus estudiantes',
    messagesAndNotifications: 'Mensajes y Notificaciones',
    notifications: 'Notificaciones',
    notificationsDescription: 'Tienes {{number}} notificaciones sin marcar',
    featuresForStudents: 'Funciones de Ubot para estudiantes',
    tutorialDescriptionStudent1: 'Haz tus actividades',
    tutorialDescriptionStudent2: 'Ver todo el contenido de tus cursos',
    tutorialDescriptionStudent3: 'Sigue tu progreso',

    // UBOT INSTITUTION CONFIGURATION
    institutionInfo: 'Información institucional',
    institutionProfileImages: 'Imágenes de perfil de la institución',
    institutionImages: 'Imágenes de la institución',
    institutionProfileImage: 'Imagen de perfil de la institución (10 MB máx.)',
    institutionImageProfileImageFormat: 'Formato de imagen: PNG o JPG. Tamaño recomendado 300px x 300px',
    imagePreview: 'Vista previa de la imagen',
    institutionHeaderImage: 'Imagen del encabezado de la institución (10 MB máx.)',
    institutionImageHeaderImageFormat: 'Formato de imagen: PNG (fondo transparente). Tamaño recomendado: 600px x 200px',
    updateImage: 'Cargar imagen',
    ownerAdmin: 'Propietario / Administradores',
    plan: 'Plan',
    institutionType: 'Tipo de institución',
    schedule: 'Cronograma',
    institutionImage: 'Imagen de la institución',
    institutionLogoDescription: 'El formato de la imagen debe estar en PNG o JPG. No debe ser más grande que 300px x 300px',
    noImage: 'Sin imágen',
    subOffice: 'Oficinas',
    addOffice: 'Agregar una nueva oficina',
    owner: 'Propietario',
    changeOwner: 'Cambiar propietario',
    youAreOwner: 'Tu eres el propietario de esta institución',
    admins: 'Administradores',
    firstnames: 'Nombre/s',
    lastnames: 'Apellido/s',
    product: 'Productos',
    newOwner: 'Nuevo propietario',
    addAdmin: 'Agregar administrador',
    newAdmin: 'Nuevo administrador',
    yourPlan: 'Tu plan',
    changeYourPlan: 'Cambiar tu plan',
    contactPhone: 'Número telefónico de contacto',
    noSubOffice: 'Sin oficina secundaria',
    noData: 'Sin datos',
    confirmGiveOwnerAccess: '¿Estás seguro darle el acceso de propietario a ',
    confirmDeleteAdmin: '¿Está seguro de eliminar a {{name}} como administrador?',
    giveOwnerAccessDescription1: 'Si das acceso de propietario, serás un administrador.',
    giveOwnerAccessDescription2: 'Solo puedes recuperar el propietario si alguien te asigna.',
    confirmDeleteAdminDescription: 'Si lo eliminas, este usuario ya no será un administrador.',
    delete: 'Borrar',
    selectProduct: 'Seleccionar producto',
    categoryName_academic: 'Academics',
    categoryName_analytics: 'Analytics',
    categoryName_communication: 'Communications',
    selectAll: 'Seleccionar todo',
    permissionName_create_career: 'Crear carrera',
    permissionName_create_approve_course: 'Crear y aprobar curso',
    permissionName_calendar_broadcast: 'Calendario y lista de difusión',
    permissionName_access_analytics: 'Acceso a Analytics',
    permissionName_access_communication: 'Acceso a Communications',
    permissionName_chat_teacher: 'Chatear con el profesor',
    permissionName_chat_student: 'Chatear con el alumno',
    permissionName_send_survey_template: 'Enviar encuesta y plantillas',
    
    smartyDescription1: '¡Hola, {{username}}! ¿Tienes alguna pregunta?',
    smartyDescription2: 'Puedes contactarme haciendo clic ',
    smartyDescription3: ' aquí',
    smartyDescription4: ' o a través de ',
    smartyDescription5: ' o ',
}