export const TRANSLATIONS_EN = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Internal server error',
    UNAUTHORIZED: 'Unauthorized',
    TOKEN_EXPIRED: 'Token expired',
    NOT_FOUND: 'Service not found',
    USER_DUPLICATED: 'Duplicated user',
    BAD_REQUEST: 'Bad request',
    USER_NOT_FOUND: 'User not found',
    PHONE_REQUIRED: 'Phone required',
    PHONE_DUPLICATED: 'Duplicated phone number',
    EMAIL_REQUIRED: 'Email required',
    EMAIL_DUPLICATED: 'Duplicated email',
    PASSWORD_REQUIRED: 'Password required',
    WRONG_PASSWORD: 'Wrong password',
    INVALID_USER: 'Invalid user',
    NOT_EMAIL_OWNER: 'You are not owner of this email',
    NOT_PHONE_OWNER: 'You are not owner of this phone',
    EMAIL_NOT_FOUND: 'Email not found',
    PHONE_NOT_FOUND: 'Phone not found',
    SENDING_EMAIL_ERROR: 'Sending email failed',
    SENDING_MESSAGE_ERROR: 'Sending message failed',
    NO_PERMISSION: 'You have no permission to do this action',
    INSTITUTION_NOT_FOUND: 'Institution not found',
    ACCOUNT_NOT_VERIFIED: 'Account not verified',
    INVALID_TOKEN: 'Invalid token',
    SOMETHING_WRONG: 'Something went wrong, please contact with support',
    SOCIAL_ACCOUNT_NOT_FOUND: 'Social account does not exist or you are not owner of that account',

    ERROR_AVATAR_FILE_SIZE: 'File size is too large',

    ACCOUNT_UPDATED_SUCCESS: 'Account updated successfully',
    CONTACT_UPDATED_SUCCESS: 'Contact updated successfully',
    UPDATE_PASSWORD_SUCCESS: 'Password updated successfully',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verify email sent successfully',
    SEND_VERIFY_CODE_SUCCESS: 'Verify code sent successfully',
    CREATE_OWNER_SUCCESS: 'You have added a new owner. The new owner has to verify the account',
    CREATE_ADMIN_SUCCESS: 'You have added a new administrator',
    DELETE_ADMIN_SUCCESS: 'You have deleted an administrator',

    // FORM VALIDATION
    fieldRequired: 'This field is required',
    fieldInvalid: 'Invalid value',
    weakPassword: 'Use 8 or more characters with a mix of letters, numbers & symbols',
    fieldUnconfirmed: 'Confirm field correctly',

    // FORM PLACEHOLDER
    user: 'User',
    password: 'Password',
    emailOrPhone: 'E-mail address or Cellphone number',
    enterCode: 'Enter your code',
    selectArea: 'Select areas',
    selectNativeLanguage: 'Select native language',
    selectOtherLanguage: 'Select other language',
    select: 'Select',

    // FORM LABEL
    name: 'Name',
    lastname: "Last name",
    secondName: "Second name",
    surname: "Surname",
    secondLastname: "Second last name",
    secondSurname: "Second surname",
    birthday: "Birth date",
    nationality: 'Nationality',
    idNumber: 'ID number',
    email: 'Email',
    phone: 'Mobile',
    repeatPassword: 'Repeat password',
    enterEmailPhone: 'Enter your email, phone',
    verificationCodeToEmail: 'A verification code to retrieve the password was sent to your email',
    enterNewPassword: 'Enter your new password',
    repeatNewPassword: 'Repeat your new password',
    country: 'Country',
    city: 'City',
    address: 'Address',
    state: 'State',
    secondNationality: 'Second nationality',
    secondIdentification: 'Second identification',
    secondPassport: 'Second passport',
    avatarOption1: "Select image from computer (maximum 1MB)",
    avatarOption2: "Load image from link",
    avatarOption3: "Use default image",
    editImage: "Edit image",
    ok: "OK",
    actual: "Actual",
    newPassword: "New password",
    confirmPassword: "Confirm new password",
    interestArea: "Interest areas",
    nativeLanguage: "Native language",
    otherLanguage: "Other language",
    sector: "Sector",
    subSector: "Sub - Sector",
    numberOfStudent: "Number of students",
    noInfo: "No info",
    numberOfCourse: "Number of courses",
    since: "Since",
    institutionName: 'Institution name',
    institutionEmail: 'Institution email',
    institutionPhone: 'Contact phone number',
    dateOfOrigin: 'Date of origin',
    description: 'Description',
    googleMapLink: 'Link to google map',
    website: 'Website',

    // HOME
    bannerDescription: "We are the new educational monitoring and follow-up system for classrooms managed by WhatsApp",
    register: "Register",
    homeCharacterTitle1: "Monitoring of evaluations",
    homeCharacterDescription1: "U · Bot provides teachers with essential monitoring tools to enhance their students' learning process.",
    homeCharacterTitle2: "Real time messaging",
    homeCharacterDescription2: "Integrated WhatsApp messaging so that you do not miss any progress and are in permanent contact with your teachers, tutors, advisers and colleagues.",
    homeCharacterTitle3: "Help available 24 hours a day",
    homeCharacterDescription3: "We present to you Smarty, a robot with artificial intelligence that will help the teacher and the student 24 hours a day, 365 days a year.",
    homeCharacterDescription3Important: "Smarty will get smarter and smarter.",
    institutionSectionTitle: "Institutions that accompany us",
    characterItemSectionTitle: "Main features",
    characterItemDescription1: "Chatbot specialized in education",
    characterItemDescription2: "Student tracking through learning milestones",
    characterItemDescription3: "Administration and registration of academic processes",
    characterItemDescription4: "Metrics and data for organization of student groups",
    characterItemDescription5: "Reminders, videos, infographics, queries through WhatsApp",
    characterItemDescription6: "Tools for institutions to make strategic decisions",
    characterItemDescription7: "Motivation system and positive reinforcement based on achievements and progress",
    characterItemDescription8: "Training the teachers of the future",
    demo: "Demo",
    contact: "Contact",
    copyRight: '© All rights reserved',

    // LOGIN PAGE
    savePassword: 'Save password and user for a future login',
    login: 'Login',
    loginWithGoogle: 'Login with Google',
    loginWithFacebook: 'Login with Facebook',
    loginWithLinkedIn: 'Login with LinkedIn',
    loginWithMicrosoft: 'Login with Microsoft',
    forgotPassword: 'Forgot password',
    newToUbot: 'New to Ubot',
    signup: 'Sign up',
    welcomeToUbot: 'Welcome to Ubot',
    selectLanguage: 'Select language',

    // SIGNUP PAGE
    signupWithEmailPassword: 'Sign up with email & password',
    signupWithGoogle: 'Sign up with Google',
    signupWithFacebook: 'Sign up with Facebook',
    signupWithLinkedIn: 'Sign up with LinkedIn',
    signupWithMicrosoft: 'Sign up with Microsoft',
    backToCreateAccount: 'Back to create an account',
    contactInfo: 'Contact info',
    signupAgreement1: 'By creating an account, I declare that I have read and accepted Ubot',
    termOfUse: 'Terms of Use',
    and: 'and',
    privacyPolicy: 'Privacy policy',
    haveAnAccount: 'Have an account',
    confirmYourEmail: 'Confirm your email',
    sendVerificationEmail: 'We will send a verification email to ',
    notReceivedVerificationEmail: 'If you have not received the confirmation email, check spam or ',
    forwardEmail: 'forward email here',

    // FORGOT PASSWORD PAGE
    findYourAccount: "Find your account",
    search: "Search",
    checkYourEmail: 'Check your email',
    verify: 'Verify',
    resetYourPassword: 'Reset your password',
    resetPassword: 'Reset password',
    rememberPassword: 'Remember me',

    // VERIFY ACCOUNT PAGE
    accountAlreadyVerifiedDescription: "Your account already verified",
    emailAlreadyVerifiedDescription: "Your email already verified",
    accountVerifiedSuccessDescription: "Congrats, your account has been confirmed!",
    emailVerifiedSuccessDescription: "Congrats, your email has been confirmed!",
    accountVerifiedFailedDescription: "Oops, account verification has been failed",
    emailVerifiedFailedDescription: "Oops, email verification has been failed",
    goBackToUbot: "Go back to Ubot",

    // ACCEPT INVITATION PAGE
    acceptMessage: 'Congrats, you have been registered as {{ role }} in {{ institutionName }}',
    roleName1: 'Owner',
    roleName2: 'Admin',
    roleName3: 'Teacher',
    roleName4: 'Student',


    // LMS Layout

    // Welcome PopUp
    emailAddressConfirmed: 'Your email address has been confirmed',
    loremIpsum: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
    youCanStudyAnything: 'You can study anything',
    searchCourse: 'Search course',
    wantBeTeacher: 'Want to be a teacher',
    createYourCourse: 'Create your own courses',
    wantCreateInstitution: 'Want to create your institution',
    createInstitution: 'Create institution',
    welcomePopUpDescription1: 'I\'m Smarty, your learning partner! send me a Hi! to stay connected',
    welcomePopUpDescription2: 'Search for training courses of interest to you',
    welcomePopUpDescription3: 'You can create and share your courses in this space',
    welcomePopUpDescription4: 'In this learning management space, institutions take center stage. You can create educational institutions, governmental institutions, independent institutions, companies, etc.',
    next: 'Next',
    back: 'Back',
    gotIt: 'Got it',

    // HEADER
    courses: 'Courses',
    tutorials: 'Tutorials',
    createYourSite: 'Create your own space',
    createYourInstitution: 'Create your institution',
    profile: 'Profile',
    configuration: 'Configuration',
    messageAndNotification: 'Messages and notifications',
    darkMode: 'Dark mode',
    signOut: 'Logout',
    allRightReserved: '© All rights reserved',

    // ACCOUNT PAGE
    generalInformation: 'General information',
    contactInformation: 'Contact information',
    summery: 'Biography',
    security: 'Security',

    profilePhoto: 'Profile photo',
    change: 'Change',
    selectFile: 'Select file',
    personalInformation: 'Personal information',
    edit: 'Edit',
    allName: 'Name/s and last name/s',
    gender: 'Gender',
    residenceCity: 'City of residence',
    residenceState: 'State of residence',
    residenceCountry: 'Country of residence',
    timezone: 'Timezone',
    identification: 'Identification',
    passport: 'Passport',
    genderundefined: 'Unknown',
    gender0: 'Other',
    gender1: 'Male',
    gender2: 'Female',
    addNationality: 'Add nationality',
    cancel: 'Cancel',
    save: 'Save',
    accounts: 'Accounts',
    noLinkedAccount: 'You don\'t have linked account',
    registeredWith_google: 'Registered with Google',
    registeredWith_facebook: 'Registered with Facebook',
    registeredWith_linkedin: 'Registered with LinkedIn',
    registeredWith_microsoft: 'Registered with Microsoft',
    confirmDeleteSocial: '<span style="font-weight: normal;">Do you want delete <b>{{account}}</b>?</span>',
    socialAccount_google: 'Google Account',
    socialAccount_facebook: 'Facebook Account',
    socialAccount_linkedin: 'LinkedIn Account',
    socialAccount_microsoft: 'Microsoft Account',
    confirmDeleteSocialDescription: 'This action is final, if you delete this account, it will be unlinked.',
    mainEmail: 'Main e-mail',
    otherEmail: 'Other e-mail',
    mainPhone: 'Main mobile',
    otherPhone: 'Other mobile',
    whatsapp: 'Whatsapp',
    telegram: 'Telegram',
    telegramAccount: 'Telegram account',
    verifyAccount: 'Verify account',
    verifiedEmail: 'Everything is ok, this mail is verified',
    unVerifiedEmail: 'This email has not been verified',
    unVerifiedAccount: 'Unverified account',
    verifiedAccount: 'Verified account',
    verifiedPhone: 'Everything is ok, this number is verified',
    unVerifiedPhone: 'This number has not been verified',
    resendVerificationEmail: 'Resend verification mail',
    sendVerificationEmailButton: 'Send verification mail',
    sendVerificationCodeButton: 'Send verification code',
    resendVerificationMessage: 'Resend verification message',
    addEmail: 'Add email',
    addPhone: 'Add mobile',
    verifyTelegramAccount: 'Verify telegram account',
    stepNumber: 'Step {{step}}',
    telegramVerificationStep1: 'Enter telegram',
    telegramVerificationStep2_1: 'Go to the following link',
    telegramVerificationStep2_2: 'or search for the user',
    telegramVerificationStep3: 'Send by this means the email with which you are registered in the system',
    enterVerificationCode: 'Enter verification code',
    makeSureDigitCode: 'Make sure you receive 6 digit code',
    resendCode: 'Resend code',
    coverLetter: 'Bio',
    tagTitle: 'I\'m interested',
    userLanguageTitle: 'Languages',
    mainUserLanguage: 'Main language',
    otherUserLanguage: 'Other language',
    changePassword: 'Change password',


    // UBOT HOME
    ubotHome: 'Ubot home',
    noInstitution: 'You don\'t have any institution yet',
    noInstitutionConfiguration: 'You have institutions to be approved',
    completeProfileMessage: 'Remember to complete your profile ',
    completeProfileMessage1: 'here :)',
    homeCardTitle1: 'Want to study something?',
    homeCardTitle2: 'Want to teach?',
    homeCardTitle3: 'Create a new institution',
    homeCardSubTitle1: 'Open courses',
    homeCardButton1: 'Search courses',
    homeCardButton2: 'Create a course',
    homeCardButton3: 'Create institution',
    homeCardDescription1: 'Search for training courses of interest to you',
    homeCardDescription2: 'You can create and share your courses in this space',
    homeCardDescription3: 'In this learning management space, institutions take center stage. You can create educational institutions, governmental institutions, independent institutions, companies, etc.',

    // UBOT INSTITUTION CREATE
    backToHome: 'Back to home',
    choosePlanTitle: 'Let\'s start creating the perfect institution for you!',
    choosePlanDescription: 'Please, select some options below so Smarty could help you to find an ideal plan for your needs.',
    searchMyPlan: 'Search my plan',
    searchAgain: 'Search again',
    customizePlanTitle: 'Customize your plan',
    confirmPlanTitle: 'Confirm your plan',
    confirmPlanDescription: 'Leave us your contact information and a person from the commercial area will contact you soon',
    institutionInformation: 'Institution information',
    billingAddress: 'Billing address',
    confirmPlan: 'Confirm plan',
    institutionSuccessEmailTitle: 'Thanks for creating an institution with us!',
    institutionSuccessEmailDescription: 'You will be receiving a verification email with the payment details',
    perMonth: 'P/month',
    requestDemo: 'Demo',
    freePlan: 'Free plan',
    requestDemoDescription: 'Select this option to create a specific demo for you',
    customizePlan: 'Customize plan',
    customizePlanDescription: 'Select this option to create a specific demo for you',
    RECOMMENDED_PLAN: 'RECOMMENDED PLAN',
    DEMO_PLAN: 'DEMO PLAN',
    FREE_PLAN: 'FREE PLAN',
    CUSTOMIZE_PLAN: 'CUSTOMIZE PLAN',
    selectPlan: 'Select plan',
    editPlan: 'Edit plan',
    selectCharacterOfInstitution: 'Select the characteristics of your institution',
    contactUs: 'Contact us',

    currency: 'Currency',
    currency_usd: 'USD U.S. Dollar',
    currency_clp: 'CLP Chilean Peso',
    currency_ars: 'ARS Argentine Peso',
    currency_brl: 'BRL Brazilian Real',

    productDescription_institution_course_number: 'Maximum {{number}} courses',
    productDescription_institution_student_number: 'Maximum {{number}} students',
    productDescription_use_telegram: 'Use Telegram',
    productDescription_use_whatsapp: 'Use WhatsApp',
    productDescription_course_student_number: 'Maximum {{number}} students per course',
    productDescription_storage: '{{number}}GB saved data',
    productDescription_other_platform: 'Integration other platforms',
    productDescription_access_analytics: 'Access to analytics',
    productDescription_access_communication: 'Access to communication',
    productDescription_survey_number: 'Maximum {{number}} surveys',
    productDescription_workspace_number: 'Maximum {{number}} workspaces',
    productDescription_message_number: 'Maximum {{number}} messages',

    sectorName_education: 'Education',
    sectorName_business: 'Company / Business',
    sectorName_ngo: 'NGO / Foundation',
    sectorName_go: 'Government institution',
    sectorName_person: 'Independent teacher / professor',
    noSubSector: 'No sub sector',

    subSectorName_public_university: 'Public university',
    subSectorName_private_university: 'Private university',
    subSectorName_public_tertiary_education: 'Public tertiary education',
    subSectorName_private_tertiary_education: 'Private tertiary education',
    subSectorName_public_college: 'Public college/high school',
    subSectorName_private_college: 'Private college/high school',
    subSectorName_institution: 'Institute',

    subSectorName_farming: 'Farming; plantations, other rural sectors',
    subSectorName_feeding: 'Feeding; drinks; tobacco',
    subSectorName_commerce: 'Commerce',
    subSectorName_construction: 'Construction',
    subSectorName_education: 'Education',
    subSectorName_transport_manufacture: 'Manufacture of transport equipment',
    subSectorName_public_function: 'Public function',
    subSectorName_hotel_restoraunt: 'Hotels, restaurants, tourism',
    subSectorName_chemical_industry: 'Chemical industries',
    subSectorName_medical_electrical_engineering: 'Mechanical and electrical engineering',
    subSectorName_media_culture: 'Media, culture; graphics',
    subSectorName_mining: 'Mining (coal, other mining)',
    subSectorName_oil_gas_production: 'Oil and gas production; oil refining',
    subSectorName_base_metal_production: 'Base metal production',
    subSectorName_postal_telecommunication_service: 'Postal and telecommunications services',
    subSectorName_health_service: 'Health services',
    subSectorName_financial_service: 'Financial services; professional services',
    subSectorName_utilities: 'Utilities (water; gas; electricity)',
    subSectorName_forestry: 'Forestry; wood, cellulose; paper',
    subSectorName_transportation: 'Transportation (including civil aviation; railways; road transportation)',
    subSectorName_marine_transport: 'Marine transport; ports, fishing; internal transport',

    // UBOT INSTITUTION DASHBOARD
    admin: 'Admin',
    teacherTrainer: 'Trainer / Teacher',
    studentApprentice: 'Student / Apprentice',
    home: 'Home',
    viewInstitution: 'View institution',
    goToAcademics: 'Go to Academic',
    statistics: 'Statistics',
    goToAnalytics: 'Go to Analytics',
    messages: 'Messages',
    goToCommunication: 'Go to Communication',
    activeCourse: 'Active courses',
    teachers: 'Teachers',
    activities: 'Activities',
    activeTeacher: 'Active teachers',
    activeStudent: 'Active students',
    courseInProgress: 'Courses in progress',
    approved: 'Approved',
    disapproved: 'Disapproved',
    courseEscape: 'Dropout rate',
    youHaveUncheckedMessage: 'You have {{ number }} unchecked message',
    youHaveUnsentSurvey: 'You have {{ number }} surveys without sending',
    youHaveNoCourse: 'You don\'t have any courses here.',
    totalNumberOfCourse: 'Total number of courses',
    totalNumberOfCourseDescription: 'You have {{number}} courses on your institution',
    totalNumberOfCourseDescriptionTeacher: 'You are enrolled in {{number}} courses',
    courseInProgressDescription: 'You have {{number}} active courses',
    totalNumberOfTeacher: 'Total number of teachers',
    totalNumberOfTeacherDescription: '{{number}} teachers',
    totalNumberOfStudent: 'Total number of students',
    totalNumberOfStudentDescription: '{{number}} students',
    chats: 'Chats',
    chatsDescription: 'You have {{number}} unchecked messages',
    surveysInProcess: 'Surveys in process',
    surveysInProcessDescription: 'You have {{number}} surveys without sending',
    contacts: 'Contacts',
    contactsDescription: 'You have {{number}} contacts in your agenda',
    statisticDescription: 'Here you can see the educational analytics of your institution:',
    performance: 'Performance',
    approvalRate: 'Approval rate',
    andMore: 'and more',
    numberOfActivityToQualify: 'Number of activities to qualify',
    numberOfActivityToQualifyDescription: '{{number}} activities to qualify',
    featuresForTeachers: 'Ubot features for teachers',
    goToTutorials: 'Go to tutorials',
    tutorialDescription: 'Here you will be able to:',
    tutorialDescription1: 'Upload and create the content of your courses',
    tutorialDescription2: 'Qualify',
    tutorialDescription3: 'Chat',
    tutorialDescription4: 'Follow the progress of your students',
    messagesAndNotifications: 'Messages and Notifications',
    notifications: 'Notifications',
    notificationsDescription: 'You have {{number}} unchecked notifications',
    featuresForStudents: 'Ubot features for students',
    tutorialDescriptionStudent1: 'Do your activities',
    tutorialDescriptionStudent2: 'See all the content of your courses',
    tutorialDescriptionStudent3: 'Follow your progress',

    // UBOT INSTITUTION CONFIGURATION
    institutionInfo: 'Institution info',
    institutionProfileImages: 'Institution profile images',
    institutionImages: 'Institution images',
    institutionProfileImage: 'Institution profile image (10MB max)',
    institutionImageProfileImageFormat: 'Image format: PNG or JPG. Recommended size 300px x 300px',
    imagePreview: 'Image preview',
    institutionHeaderImage: 'Institution header image (10MB max)',
    institutionImageHeaderImageFormat: 'Image format: PNG (transparent background). Recommended size: 600px x 200px',
    updateImage: 'Upload image',
    ownerAdmin: 'Owner / Administrators',
    plan: 'Plan',
    institutionType: 'Institution type',
    schedule: 'Schedule',
    institutionImage: 'Institution image',
    institutionLogoDescription: 'The image format must be in PNG or JPG. It should not be larger than 300px x 300px',
    noImage: 'No image',
    subOffice: 'Sub-offices',
    addOffice: 'Add new office',
    owner: 'Owner',
    changeOwner: 'Change owner',
    youAreOwner: 'You are the owner of this institution',
    admins: 'Administrators',
    firstnames: 'Name/s',
    lastnames: 'Surname/s',
    product: 'Products',
    addAdmin: 'Add administrator',
    newOwner: 'New owner',
    newAdmin: 'New administrator',
    yourPlan: 'Your plan',
    changeYourPlan: 'Change your plan',
    contactPhone: 'Contact phone number',
    noSubOffice: 'No sub office',
    noData: 'No data',
    confirmGiveOwnerAccess: 'Are you sure to give the owner access to',
    confirmDeleteAdmin: 'Are you sure to delete {{name}} as administrator?',
    giveOwnerAccessDescription1: 'If you give access, you will be an Administrator.',
    giveOwnerAccessDescription2: 'You can only regain owner if someone assigns you.',
    confirmDeleteAdminDescription: 'If you delete, this user will not be an admin any more.',
    delete: 'Delete',
    selectProduct: 'Select product',
    categoryName_academic: 'Academics',
    categoryName_analytics: 'Analytics',
    categoryName_communication: 'Communication',
    selectAll: 'Select all',
    permissionName_create_career: 'Create career',
    permissionName_create_approve_course: 'Create and approve course',
    permissionName_calendar_broadcast: 'Calendar and broadcast list',
    permissionName_access_analytics: 'Access to analytics',
    permissionName_access_communication: 'Access to Communications',
    permissionName_chat_teacher: 'Chat with teacher',
    permissionName_chat_student: 'Chat with student',
    permissionName_send_survey_template: 'Send survey and template',
    
    smartyDescription1: 'Hi {{username}}! Do you have any question?',
    smartyDescription2: 'You can contact me clicking ',
    smartyDescription3: ' here',
    smartyDescription4: ' or through ',
    smartyDescription5: ' or ',
}