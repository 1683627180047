import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	productCategoryData: null,
	permissionData: null,
	productData: null,
};

const updateProductCategoryData = (state, action) => {
	return updateState(
		state,
		{
			productCategoryData: action.productCategoryData
		}
	)
};

const updatePermissionData = (state, action) => {
	return updateState(
		state,
		{
			permissionData: action.permissionData
		}
	)
};

const updateProductData = (state, action) => {
	return updateState(
		state,
		{
			productData: action.productData
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_PRODUCT_CATEGORY_DATA: return updateProductCategoryData(state, action);
		case actionTypes.UPDATE_PERMISSION_DATA: return updatePermissionData(state, action);
		case actionTypes.UPDATE_PRODUCT_DATA: return updateProductData(state, action);
		default: return state;
	}
};

export default reducer;