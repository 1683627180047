import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	institutionData: null,
	institutionUserData: null,
	institutionInfoData: null,
};

const updateInstitutionData = (state, action) => {
	return updateState(
		state,
		{
			institutionData: action.institutionData
		}
	)
};

const updateInstitutionUserData = (state, action) => {
	return updateState(
		state,
		{
			institutionUserData: action.institutionUserData
		}
	)
};

const updateInstitutionInfoData = (state, action) => {
	return updateState(
		state,
		{
			institutionInfoData: action.institutionInfoData
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_INSTITUTION_DATA: return updateInstitutionData(state, action);
		case actionTypes.UPDATE_INSTITUTION_USER_DATA: return updateInstitutionUserData(state, action);
		case actionTypes.UPDATE_INSTITUTION_INFO_DATA: return updateInstitutionInfoData(state, action);
		default: return state;
	}
};

export default reducer;