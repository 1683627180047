import {useEffect} from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Loadable from "react-loadable";

import {notification} from "antd";
import Loader from './components/Loader/Loader';
import UbotToast from "./components/Wrapper/UbotToast";

import {setMessage, setUbotToast} from "./service/store/action/theme";

import './App.less';

const loading = () => <><Loader/></>;

const AuthLayout = Loadable({
    loader: () => import('./layout/AuthLayout/AuthLayout'),
    loading,
});

const LMSLayout = Loadable({
    loader: () => import('./layout/LMSLayout/LMSLayout'),
    loading,
});

const LandingLayout = Loadable({
    loader: () => import('./layout/LandingLayout/LandingLayout'),
    loading,
});

function App() {

    const dispatch = useDispatch();
    const message = useSelector((state) => {
        return {
            type: state.theme.messageType,
            content: state.theme.messageContent,
        };
    });
    const ubotMessage = useSelector(state => {
        return {type: state.theme.ubotToastType, content: state.theme.ubotToastContent};
    });

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: message.type.toUpperCase(),
                description: message.content,
                onClose: () => dispatch(setMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!!ubotMessage.content) {
            setTimeout(() => dispatch(setUbotToast(null, null)), 5000);
        }
    }, [ubotMessage.content]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <UbotToast visible={!!ubotMessage.content} type={ubotMessage.type} content={ubotMessage.content} />
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" component={AuthLayout}/>
                    <Route path="/lms" component={LMSLayout}/>
                    <Route path="/" component={LandingLayout}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
