import * as actionTypes from '../actionTypes';
import axios from "axios";
import {API_BASE_URL} from "../../../util/constant";
import {errorManagementHandler, setLoading, setMessage, setUbotToast} from "./theme";
import i18n from "../../../translations/i18n";

export const updateUserData = userData => {
	return {
		type: actionTypes.UPDATE_USER_DATA,
		userData: userData
	}
};

export const getUserData = (history) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) dispatch(logout(history));
        else {
            dispatch(setLoading(true));
            axios
                .get(`${API_BASE_URL}/auth/userData/get`, {headers: {Authorization: `Bearer ${token}`}})
                .then(res => {
                    const userData = res.data.data;
                    dispatch(updateUserData(userData));
                    dispatch(setLoading(false));
                })
                .catch(e => dispatch(errorManagementHandler(e, history)));
        }
    }
};

export const editUserData = (data, history, callback) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        dispatch(setLoading(true));
        axios
            .put(`${API_BASE_URL}/auth/userData/update`, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                dispatch(updateUserData(res.data.data));
                dispatch(setLoading(false));
                dispatch(setMessage('success', i18n.t('ACCOUNT_UPDATED_SUCCESS')));
                callback();
            })
            .catch(e => dispatch(errorManagementHandler(e, history)));
    };
};

export const uploadUserAvatar = (data, history, callback) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        dispatch(setLoading(true));
        axios
            .put(`${API_BASE_URL}/auth/userData/uploadAvatar`, data, {headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }})
            .then(res => {
                dispatch(updateUserData(res.data.data));
                dispatch(setLoading(false));
                dispatch(setMessage('success', i18n.t('ACCOUNT_UPDATED_SUCCESS')));
                callback();
            })
            .catch(e => dispatch(errorManagementHandler(e, history)));
    };
};

export const updateUserContact = (data, history, callback) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        dispatch(setLoading(true));
        axios
            .put(`${API_BASE_URL}/auth/userData/updateContact`, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                dispatch(updateUserData(res.data.data));
                dispatch(setLoading(false));
                dispatch(setUbotToast('success', i18n.t('CONTACT_UPDATED_SUCCESS')));
                callback();
            })
            .catch(e => dispatch(errorManagementHandler(e, history)));
    };
};

export const deleteSocialAccount = (id, callback) => {
    return (dispatch, getState) => {
        const token = localStorage.getItem('token');
        dispatch(setLoading(true));
        axios
            .delete(`${API_BASE_URL}/auth/oauth/unlink/${id}`, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => {
                const userData = getState().auth.userData;
                dispatch(updateUserData({
                    ...userData,
                    social_list: userData.social_list.filter(item => item.id !== id),
                }))
                dispatch(setLoading(false));
                callback();
            })
            .catch(e => dispatch(errorManagementHandler(e)));
    };
};

export const logout = history => {
    return dispatch => {
        localStorage.removeItem('token');
        window.open('/auth', '_parent');
    }
};