import {combineReducers} from 'redux'

import theme from './theme';
import data from './data';
import auth from './auth';
import institution from './institution';

export default combineReducers({
	theme,
	data,
	auth,
	institution,
})