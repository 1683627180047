export const SET_THEME = 'SET_THEME';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_UBOT_TOAST = 'SET_UBOT_TOAST';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const UPDATE_INSTITUTION_DATA = 'UPDATE_INSTITUTION_DATA';
export const UPDATE_INSTITUTION_USER_DATA = 'UPDATE_INSTITUTION_USER_DATA';
export const UPDATE_INSTITUTION_INFO_DATA = 'UPDATE_INSTITUTION_INFO_DATA';

export const UPDATE_PRODUCT_CATEGORY_DATA = 'UPDATE_PRODUCT_CATEGORY_DATA';
export const UPDATE_PERMISSION_DATA = 'UPDATE_PERMISSION_DATA';
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA';